import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {store} from './redux/store';
import {Provider} from 'react-redux';

ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);
console.log(process.env.REACT_APP_ENVIRONMENT);
// if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
//   console.log = () => {};
// }
