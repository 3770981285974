export const realtime = [{
  'temp': 33.22,
  'rh': 68.08,
  'dPM1': 27,
  'dPM2': 29,
  'dPM10': 17,
}, {
  'temp': 34.22,
  'rh': 66.08,
  'dPM1': 25,
  'dPM2': 23,
  'dPM10': 18,
}, {
  'temp': 31.22,
  'rh': 65.08,
  'dPM1': 29,
  'dPM2': 22,
  'dPM10': 10,
}, {
  'temp': 38.22,
  'rh': 69.08,
  'dPM1': 22,
  'dPM2': 24,
  'dPM10': 11,
}, {
  'temp': 35.22,
  'rh': 60.08,
  'dPM1': 27,
  'dPM2': 26,
  'dPM10': 15,
}, {
  'temp': 38.22,
  'rh': 69.08,
  'dPM1': 22,
  'dPM2': 21,
  'dPM10': 20,
}];
